import { Button } from '@/components/ui/button';
import { useUser } from '@/hooks/useUser';
import { ArrowLeft, LogOut, RefreshCcw } from 'lucide-react';
import { useEffect } from 'react';
import {
  useNavigate,
  useRouteError,
  isRouteErrorResponse,
  useSearchParams,
} from 'react-router-dom';

export default function ErrorPage() {
  const error = useRouteError();
  const navigate = useNavigate();
  const loggedin = useUser().signedIn;

  const routerError = isRouteErrorResponse(error) ? error : null;
  const otherError = !isRouteErrorResponse(error) ? (error as any) : null;

  const isDynamicFetchError =
    otherError?.message
      ?.toString()
      ?.includes('Failed to fetch dynamically imported module') || false;

  const is404 = routerError?.status === 404;

  const [search, setSearch] = useSearchParams();

  useEffect(() => {
    if (isDynamicFetchError && search.get('refech') !== 'true') {
      setSearch({ refech: 'true' });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  }, [isDynamicFetchError]);

  return (
    <div id="error-page" className="h-screen flex items-center justify-center">
      <div className="border border-solid border-slate-200 px-10 py-20 rounded-lg shadow-sm">
        <div className="text-6xl font-bold">{is404 ? '404' : 'Oops!'}</div>
        <div className="text-rose-600 text-lg">
          {is404
            ? 'Page you are looking for not found!'
            : 'Sorry, an unexpected error has occurred.'}
        </div>
        <div className="text-sky-600 italic ">
          {routerError?.statusText || otherError?.message}
        </div>
        <div className="flex items-center gap-x-4 mt-12">
          <Button variant="secondary" onClick={() => window.history.back()}>
            <ArrowLeft className="size-4 mr-1" /> Go Back
          </Button>
          <Button
            variant={'informative'}
            onClick={() => window.location.reload()}
          >
            <RefreshCcw className="size-4 mr-1" /> Reload
          </Button>
          {loggedin && (
            <Button variant="destructive" onClick={() => navigate('/logout')}>
              <LogOut className="size-4 mr-1" /> Logout
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
