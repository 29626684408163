import {
  addMinutes,
  addMonths,
  differenceInDays,
  differenceInMinutes,
  differenceInMonths,
  differenceInYears,
  format,
} from 'date-fns';

export const _date = format(new Date(), 'yyyy-MM-dd');

export const getBreakTime = (
  start?: Date | string | null,
  duration?: number | null
) => {
  const breakEndTime =
    start && duration
      ? addMinutes(
          start instanceof Date ? start : new Date(`${start}Z`),
          duration
        )
      : null;
  const remainingBreakTime = breakEndTime
    ? differenceInMinutes(breakEndTime, new Date())
    : null;
  const toAdd = remainingBreakTime
    ? remainingBreakTime < 0
      ? 0
      : remainingBreakTime
    : 0;

  return {
    breakEndTime,
    remainingBreakTime,
    toAdd,
  };
};

export default 'No default export for this';
export const calculateAge = (dt: Date) => {
  if (!dt) return 0;
  let age = differenceInYears(new Date(), dt);
  if (age === 0) {
    // calculate months and days
    const months = differenceInMonths(new Date(), dt);
    const daysDate = addMonths(dt, months);
    const days = differenceInDays(new Date(), daysDate);
    return `${months > 0 ? `${months} months` : ''} ${
      days > 0 ? `${days} days` : ''
    }`;
  }
  return age;
};
